import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import { i18n } from './utils/i18n.js';
import { Vue3Mq } from "vue3-mq";


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import 'aos/dist/aos.css'; 
import 'animate.css';
import './assets/main.css';
import "./styles/common.scss";

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as CustomFontAwesome from './utils/CustomFontAwesome.js';


const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(i18n);
app.use(Vue3Mq);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')