<script setup>
import AOS from 'aos';
import 'aos/dist/aos.css';

import Header from '../components/home/Header.vue';
import SuiteDigital from '../components/home/SuiteDigital.vue';
import Potencial from '../components/home/Potencial.vue';
import Digitalize from '../components/home/Digitalize.vue';
import Blocks from '../components/home/Blocks.vue';
import NewFunctionality from '../components/home/NewFunctionality.vue';
import Cruise from '../components/home/Cruise.vue';
import Powerful from '../components/home/Powerful.vue';
import ClientApp from '../components/home/ClientApp.vue';
import TryCar from '../components/home/TryCar.vue';
import Cases from '../components/home/Cases.vue';

AOS.init({
  duration: 2000,
  once: true
})

</script>

<template>
  <main>
    <Header />
    <div class="home-container">
       <div class="home-section" data-aos="fade-up">
        <div class="home-section-inner" ref="homeSectionInner">
          <SuiteDigital />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section-inner home-section-potential">
          <Potencial />
        </div>
      </div>

      <div class="home-section home-section-digitalize" data-aos="fade-up">
        <div class="home-section-inner">
          <Digitalize />
        </div>
      </div>
      <div class="home-section"  data-aos="fade-up">
        <div class="home-section-inner">
          <Blocks />
        </div>
      </div>
      <div class="home-section home-section-newfunctionality" >
        <div class="home-section-inner">
          <NewFunctionality />
        </div>
      </div>
      <div class="home-section home-cruiser-wrapper"  data-aos="fade-up">
        <div class="home-section-inner">
          <Cruise />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section-inner">
          <Powerful />
        </div>
      </div>
      <div class="home-section"> 
        <div class="home-section-inner">
          <ClientApp />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section-inner">
          <TryCar />
        </div>
      </div>
      <div class="home-section">
        <div class="home-section-inner">
          <Cases />
        </div>
      </div> 
    </div> 
  </main>
</template>
<style lang="scss" scoped>
@media (max-width: 1000px) {
  .home-section-potential {
    padding: 0;
  }
}
</style>