<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const i18n = useI18n();
const router = useRouter();

const localeArray = {
    es: 'Español',
    en: 'English',
    fr: 'Français',
    ca: 'Català',
    pt: 'Português',
};

let locale = ref(i18n.locale)

function switchLocale(loc) {
    router.replace({ params: { lang: loc.value } });
}

onMounted(() => {
    if(locale.value == '' || !(i18n.availableLocales).includes(locale.value)) {
        locale.value = 'es'
    }
});
</script>

<template>
    <div class="custom-selector">
        <select v-model="locale" @change="switchLocale(i18n.locale)">
            <option
                v-for="(locale, i) in $i18n.availableLocales"
                :key="`locale-${i}`"
                :value="locale"
                :selected="locale === $i18n.locale"
            >
                {{ localeArray[locale] }}
            </option>
        </select>
    </div>
</template>

<style lang="scss" scoped>
    select {
        border: none;
        color: #666666;
        padding: 2px 10px;
    }
    select option {
        background: rgba(0, 0, 0, .75) ;
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
    }
</style>