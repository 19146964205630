<script setup>
    import { ref } from 'vue'
    import SuiteDigitalCar from '@/components/home/homeSuiteDigital/SuiteDigitalCar.vue'
    import SuiteDigitalIveco from '@/components/home/homeSuiteDigital/SuiteDigitalIveco.vue'
    import SuiteDigitalMoto from '@/components/home/homeSuiteDigital/SuiteDigitalMoto.vue'

    const active = ref(1)

    setInterval(function(){
        if(active.value >= 3) {
            active.value = 1
        } else {
            active.value++
        }
    }, 5000);

</script>

<template>
    <div class="carousel-wrapper w-100">
        <div class="carousel">
            <div  class="inner">
                <div class="card">
                    <TransitionGroup  name="list" tag="div" >
                        <div class="slide" v-if="active == 1">
                            <SuiteDigitalCar />
                        </div>
                        <div class="slide" v-else-if="active == 2">
                            <SuiteDigitalIveco  />
                        </div>
                        <div class="slide" v-else-if="active == 3">
                            <SuiteDigitalMoto />
                        </div>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    </div>
    <div class="app-background">
                
    </div>
</template>

<style lang="scss" scoped>
.app-background {
    background-image: url("/assets/es/home/suite/suite_bg.png");
    height: 420px;
    width: 800px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 3;
    position: relative;
}
.carousel-wrapper {
    position: relative;
    z-index: 1;
    .carousel {
        position: absolute;
        position: absolute;
        top: 56px;
        height: 420px;
        width: 800px;
    }
}
.card {
    border: none;
    background-color: transparent;
    position: relative;
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-40%,70px);
    }
    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

}
.fade-enter-active,
.fade-leave-active {
    //transition: all 5 ease;
    overflow: hidden;
    visibility: visible;
    position: absolute;
    width:100%;
    opacity: 1;
   
}

.list-enter-active {
    transition: all 2s ease-out;
}
.list-leave-active {
    transition: all 1.6s ease-out;
}
.list-enter-from {
    transform: translateX(-250px);
    opacity: .8;
}
.list-leave-to {
  opacity: .8;
  transform: translateX(220px);
}

@keyframes tonext {
    75% {
      left: 0;
    }
    95% {
      left: 100%;
    }
    98% {
      left: 100%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes tostart {
    75% {
      left: 0;
    }
    95% {
      left: -300%;
    }
    98% {
      left: -300%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }
    97% {
      scroll-snap-align: none;
    }
    99% {
      scroll-snap-align: none;
    }
    100% {
      scroll-snap-align: center;
    }
  }
  

</style>