<template>
    <div class="custom-carousel-item">
        <div class="custom-carousel-item-wrapper">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @media (max-width: 390px) {
        .custom-carousel-item-wrapper {
            height: 100%;
            width: 100%;
            max-height: 450px;
        }
    }
    @media (max-width: 800px) {
        .home-block-wrapper {
            min-width: 300px;
        }
    }
    
</style>
