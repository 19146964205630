<script setup>
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue'

const { locale } = useI18n();
let location = ref(locale.value);

watch(locale, () => {
    location = locale.value;
});
</script>
<template>
    <section class="home_functionality">
        <div class="w-100 section-container functionality-wrapper">
            <div class="wrapper">
                <div class="functionality_header">
                    <div class="title primary-text ">
                        {{ $t('home_new_functionality.info') }}
                    </div>
                    <div class="subtitle">
                        {{ $t('home_new_functionality.title') }}
                    </div>
                    <div class="subtitle_more">
                        <div class="home-new-functionality-link">
                            <router-link :to="`/${location}/invoice`" class="">
                                {{ $t('home_new_functionality.subtitle') }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-wrapper">
            <div class="inner-wrapper">
                <div class="title">
                    {{ $t('home_new_functionality.digitalize_process') }}
                    <span>
                        {{ $t('home_new_functionality.digitalize_price') }}
                    </span>
                </div>
                <div class="btn-wrapper">
                    <router-link :to="`/${location}/contact`" class="custom-btn">
                        {{ $t('home_new_functionality.contract') }}
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.home_functionality {
    background-color: black;
}
.functionality_header {
    color: white;
    width: 100%;
    text-align: center;
    .title {
        font-size: 20px;
        font-family: 'CircularStd-Bold';
        text-transform: uppercase;
    }
    .subtitle {
        font-family: 'CircularStd-Black';
        letter-spacing: -1.9px;
        font-size: 38px;
        padding-top: 20px;
    }
    .subtitle_more {
        font-size: 25px; 
        padding-top: 5px;
        .home-new-functionality-link {
            a {
                color: white;
                text-decoration: none;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    border-radius: 4px;
                    background-color: white;
                    bottom: -2px;
                    left: 0;
                    transform-origin: right;
                    transform: scaleX(0);
                    transition: transform .3s ease-in-out;
                }            
                &:hover::before {
                    transform-origin: left;
                    transform: scaleX(1);
                }
                
            }
        }
    }
}
.section-container {
    padding: 15px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image-wrapper {
    height: 545px;
    background-image: url("/assets/es/home/new_functionality/cloudactive_WEB.webp");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    .inner-wrapper {
        max-width: 1080px;
        text-align: center;
        span {
            font-family: 'CircularStd-Black';
        }
    }
    .btn-wrapper {
        display: flex;
        justify-content: center;
        a {
            font-size: 18px;
            text-decoration: none;
            display: block;
        }
    }
    .title {
        letter-spacing: -2.55px;
    }
}

@media (max-width: 1000px) { 
    .functionality-wrapper {
        display: none;
        .title {
            font-size: 1.5rem;
        }
    }
    .home_functionality .inner-wrapper {
        font-size: 1.8rem;
        padding: 10px 45px;
        line-height: 2.2rem;
        .title {
            padding-bottom: 50px;
        }
    }
    .image-wrapper {
        height: 545px;
        background-image: url("/assets/es/home/new_functionality/Fons_Carrer.png");
    }
}
@media (max-width: 600px) { 
    .functionality-wrapper {
        display: none;
        .title {
            font-size: 1.5rem;
        }
    }
    .home_functionality .inner-wrapper {
        font-size: 1.8rem;
        padding: 10px 45px;
        line-height: 2.2rem;
        .title {
            padding-bottom: 50px;
            letter-spacing: -1.1px;
        }
    }
}
</style>
