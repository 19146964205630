<script setup>
import { RouterLink, RouterView } from 'vue-router'
import { useI18n } from 'vue-i18n';
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Layout from '@/components/layout/Layout.vue'
import VueCustomCookies from '@/components/customCookies/VueCustomCookies.vue'

const { t } = useI18n();

</script>

<template>
  <Navbar />
  <Layout />
  <Footer />
  <VueCustomCookies
    :msg="t('custom_coookies.message')"
    :accept="t('custom_coookies.accept')"
    :decline="t('custom_coookies.decline')"
  />
</template>

<style scoped>
</style>
