import { i18n } from "./i18n.js";

const title = "Cloud Active Reception | ";

export function getPageTitle(key) {
  const hasKey = i18n.global.t(`route.${key}`);
  if (hasKey) {
    const pageName = i18n.global.t(`route.${key}`);
    return `${title}  ${pageName}`;
  }
  return `${title}`;
}
