<script setup>
    import { ref } from 'vue'
    import SuiteDigitalMobileCar from '@/components/home/homeSuiteDigital/mobile/SuiteDigitalMobileCar.vue'
    import SuiteDigitalMobileIveco from '@/components/home/homeSuiteDigital/mobile/SuiteDigitalMobileIveco.vue'
    import SuiteDigitalMobileMoto from '@/components/home/homeSuiteDigital/mobile/SuiteDigitalMobileMoto.vue'

    const active = ref(1)

    setInterval(function(){
        if(active.value >= 3) {
            active.value = 1
        } else {
            active.value++
        }
    }, 5000);
</script>

<template>
    <div class="carousel-wrapper-mobile w-100">
        <div class="carousel-mobile">
            <div  class="inner">
                <div class="card">
                    <TransitionGroup  name="list" tag="div" >
                        <div class="slide" v-if="active == 1">
                            <SuiteDigitalMobileCar />
                        </div>
                        <div class="slide" v-else-if="active == 2">
                            <SuiteDigitalMobileIveco  />
                        </div>
                        <div class="slide" v-else-if="active == 3">
                            <SuiteDigitalMobileMoto />
                        </div>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    </div>
    <div class="app-background-mobile"></div>
</template>

<style lang="scss" scoped>
.app-background-mobile {
    background-image: url("/assets/es/home/suite/mobile/Dispositius_Mobil.png");
    height: 320px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 3;
    position: relative;
}
.carousel-wrapper-mobile {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    .carousel-mobile {
        position: absolute;
        top: 56px;
        height: 420px;
        width: 100%;
        height: 100%;
    }
}
.card {
  border: none;
  background-color: transparent;
  position: relative;
}
.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.list-enter-active {
    transition: all 2s ease-out;
}
.list-leave-active {
    transition: all 1.6s ease-out;
}
.list-enter-from {
    transform: translateX(-250px);
    opacity: .8;
}
.list-leave-to {
  opacity: .8;
  transform: translateX(220px);
}

@keyframes tonext {
    75% {
      left: 0;
    }
    95% {
      left: 100%;
    }
    98% {
      left: 100%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes tostart {
    75% {
      left: 0;
    }
    95% {
      left: -300%;
    }
    98% {
      left: -300%;
    }
    99% {
      left: 0;
    }
  }
  
  @keyframes snap {
    96% {
      scroll-snap-align: center;
    }
    97% {
      scroll-snap-align: none;
    }
    99% {
      scroll-snap-align: none;
    }
    100% {
      scroll-snap-align: center;
    }
  }
  


  @media (max-width: 1000px) {
    .card {
      border: none;
      background-color: transparent;
      position: relative;       
      &:before {
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translate3d(200px,-100%,-150px);
      }
      
      img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      
    }
    .carousel-wrapper-mobile .carousel-mobile {
      width: 100%;
      top: 80px;
      right: 56px;
    }

    .list-enter-active {
      transition: all 2s ease-out;
    }
    .list-leave-active {
        transition: all 1.6s ease-out;
    }
    .list-enter-from {
        transform: translateX(-200px);
        opacity: .8;
    }
    .list-leave-to {
      opacity: .8;
      transform: translateX(100px);
    }
  }

  @media (max-width: 920px) {
    .carousel-wrapper-mobile .carousel-mobile {
      width: 100%;
      top: 17px;
      right: 84px;
    }
    .list-enter-from {
      transform: translateX(-150px);
      opacity: .8;
  }
    .list-leave-to {
      opacity: .8;
      transform: translateX(100px);
    }
  }
  @media (max-width: 600px) {
    .carousel-wrapper-mobile .carousel-mobile {
      width: 100%;
      top: 45px;
      right: 40px;
    }
  }
  @media (max-width: 480px) {
    .carousel-wrapper-mobile .carousel-mobile {
      width: 100%;
      top: 65px;
      right: 40px;
    }
  }
  @media (max-width: 450px) {
    .carousel-wrapper-mobile .carousel-mobile {
      width: 100%;
      top: 78px;
      right: 30px;
    }
  }
  @media (max-width: 390px) {
    .carousel-wrapper-mobile .carousel-mobile {
      width: 100%;
      top: 78px;
      right: 40px;
    }
    .list-enter-from {
      transform: translateX(-150px);
      opacity: .8;
    }
    .list-leave-to {
      opacity: .8;
      transform: translateX(40px);
  }
  }
</style>