<template>
    <section class="container">
        <div class="home_digital">
            <div class="home_suite_title">
                {{ $t('digitalize.digitalize_title') }}
                <span class="home_suite_sub_title primary-text">
                    {{ $t('digitalize.digitalize_title_branded') }}
                </span>
            </div>
            <div class="home_suite_subtitle">
                {{ $t('digitalize.digitalize_subtitle') }}
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .home_digital {
        padding-top: 130px;
        padding-bottom: 35px;
    }
    .home_suite_title,
    .home_suite_sub_title {
        font-size: 40px;
        font-family: 'CircularStd-Black';
        text-align: center;
        letter-spacing: -2.75px;
    }
    .home_suite_subtitle {
        font-size: 16px;
        text-align: center;
    }

    @media (max-width: 1000px) {
        .home_digital {
            padding-top: 60px;
            padding-bottom: 50px;
        }
        .home_suite_title,
        .home_suite_sub_title {
            font-size: 2rem;
            padding: 0 45px 30px;
            line-height: 2.1rem;
            span {
                padding: 0;
            }
        }
        
    }
    @media (max-width: 600px) { 
        .home_suite_title,
        .home_suite_sub_title {
            font-size: 2rem;
            padding: 0 45px 30px;
            line-height: 2.1rem;
            letter-spacing: -1.1px;
            span {
                padding: 0;
            }
        }
    }
</style>