<script setup>
    import { ref, onMounted, watch } from 'vue'
    import SuiteDigitalCarousel from './homeSuiteDigital/SuiteDigitalCarousel.vue';
    import SuiteDigitalMobileCarousel from './homeSuiteDigital/mobile/SuiteDigitalMobileCarousel.vue';
    import { updateBreakpoints, useMq } from "vue3-mq";
    import { useI18n } from 'vue-i18n';

    const { locale } = useI18n();
    let location = ref(locale.value);
    const mq = useMq();

    onMounted(() => {
        updateBreakpoints({
            breakpoints: {
                mobile: 600,
                desktop: 1000
            }
        })
    });

    watch(locale, () => {
        location = locale.value;
    });
</script>
<template>
    <section class="home_suite container">
        <div class="container-wrapper">
            <div class="home-suite-text-desktop">
                <div class="home_suite_title">
                    {{ $t('header.suite_digital') }}
                </div>
                <div class="home_suite_sub_title primary-text">
                    {{ $t('header.suite_digital_dms') }}
                </div>
            </div>
            <div class="home-suite-text-mobile">
                {{ $t('header.suite_digital') }} <span class="font-bold primary-text">{{ $t('header.suite_digital_dms') }} </span>
            </div>
        </div>
        <div class="container-wrapper secondary-wrapper secondary-desktop">
            <div class="home_suite_text home-suite-text-desktop">
                {{ $t('header.suite_digital_msg') }} <span class="font-bold">{{ $t('header.suite_digital_msg_bold') }} </span>
            </div>
        </div>
        <div class="container-wrapper secondary-wrapper container-home-suite-carousel" v-if="mq.desktop">
            <div class="wrapper-image">
                <SuiteDigitalCarousel />
            </div>
        </div>
        <div class="container-wrapper secondary-wrapper container-home-suite-carousel-movile" v-else>
            <div class="wrapper-image-mobile">
                <SuiteDigitalMobileCarousel />
            </div>
        </div>
        <div class="container-wrapper secondary-wrapper home-suite-desktop">
            <div class="separator-black"></div>
        </div>
        <div class="container-wrapper secondary-wrapper home-suite-desktop">
            <div class="box-wrapper">
                <div class="box">
                    <div class="box-title">
                        {{ $t('header.suite_digital_block_1') }}
                    </div>
                    <div class="box-content">
                        {{ $t('header.suite_digital_block_1_sub') }}
                    </div>
                </div>
                <div class="box">
                    <div class="box-title">
                        {{ $t('header.suite_digital_block_2') }}
                    </div>
                    <div class="box-content">
                        {{ $t('header.suite_digital_block_2_sub') }}
                    </div>
                </div>
                <div class="box">
                    <div class="box-title">
                        {{ $t('header.suite_digital_block_3') }}
                    </div>
                    <div class="box-content">
                        {{ $t('header.suite_digital_block_3_sub') }}
                    </div>
                </div>
                <div class="box">
                    <div class="box-title">
                        {{ $t('header.suite_digital_block_4') }}
                    </div>
                    <div class="box-content">
                        {{ $t('header.suite_digital_block_4_sub') }}
                    </div>
                </div>
                <div class="box">
                    <div class="box-title">
                        {{ $t('header.suite_digital_block_5') }}
                    </div>
                    <div class="box-content">
                        {{ $t('header.suite_digital_block_5_sub') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="container-wrapper secondary-wrapper home-suite-desktop">
            <div class="separator-black"></div>
        </div>
        <div class="container-wrapper secondary-wrapper home-suite-movile-show-more">
            <div class="home-suite-movile-text">
                {{ $t('header.suite_digital_msg') }} <span class="font-bold">{{ $t('header.suite_digital_msg_bold') }} </span>
            </div>
        </div>
        <div class="container-wrapper secondary-wrapper home-suite-show-more">
            <router-link :to="`/${location}/functionalities`" class="text-center home_suite_more">
                {{ $t('header.suite_digital_see_all') }}
                <font-awesome-icon icon="fa-solid fa-circle-plus" />
            </router-link>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .container-wrapper {
        line-height: 4rem;
        padding-top: 150px;
        .home_suite_title,
        .home_suite_sub_title {
            font-size: 55px;
            font-weight: bold;
            text-align: center;
            font-family: 'CircularStd-Black';
            line-height: 58px;
            letter-spacing: -2.75px;
        }
        &.secondary-wrapper {
            padding-top: 10px;
            display: flex;
            justify-content: center;
            padding: 20px 120px;
            &.container-carousel {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
            }
        }
    }
    .secondary-wrapper .home_suite_text {
        line-height: normal;
        font-size: 24px;
        text-align: center;
        letter-spacing: -1.2px;
        line-height: 31px;
    }
    .box-wrapper {
        display: flex;
        line-height: normal;
        .box {
            padding: 10px;
            letter-spacing: -0.7px;
            .box-title {
                font-size: 21px;
                font-weight: bold;
                padding-bottom: 8px;
            }
            .box-content {
                font-size: 14px;
            }
        }
    }
    a.home_suite_more {
        font-size: 1.4rem;
        font-weight: bold;
        padding-bottom: 40px;
        cursor: pointer;
        color: black;
        text-decoration: none;
        .fa-circle-plus:hover {
            color: #0047ba;
        }
    }
    .wrapper-image {
        width: 800px;
        margin: 0 auto;
    }
    .container-wrapper.secondary-wrapper.container-carousel-movile {
        display: none;
    }
    .container-wrapper.secondary-wrapper {
        display: block;
    }
    .home-suite-text-mobile {
        display: none;
    }
    .container-wrapper.secondary-wrapper.home-suite-movile-show-more{
        display: none;
    }
    .container-wrapper.secondary-wrapper.home-suite-show-more {
        text-align: center;
    }
    .home-suite-movile-show-more {
        display: none;
    }
    @media (max-width: 600px) {
        .secondary-wrapper.container-carousel {
            display: none;
        }
        .container-carousel-movile {
            display: block;
            padding: 0px;
        }
        .container-wrapper.secondary-wrapper.container-home-suite-carousel-movile {
            display: block;
            padding: 0;
        }
        .container-home-suite-carousel {
            display: none !important;
        }
    }
    @media (max-width: 1000px) {
        .container-wrapper {
            padding-top: 0px;
            &.secondary-wrapper {
                padding: 20px;
                .home_suite_text {
                    font-size: 1.2rem;
                }
            }
            &.home-suite-desktop {
                display: none;
            }
            &.secondary-wrapper.secondary-desktop {
                display: none;
            }
        }
        .home_suite_title,
        .home_suite_sub_title {
            font-size: 2rem;
            line-height: 40px;
        }
        .home-suite-text-desktop {
            display: none;
        }
        .secondary-wrapper.container-carousel {
            display: none;
        }
        .container-carousel-movile {
            display: block;
            padding: 0px;
        }
        a.home_suite_more {
            font-size: 1.2rem;
        }
        .home-suite-text-mobile {
            display: block;
            text-align: center;
            font-size: 2rem;
            line-height: 2rem;
            font-family: 'CircularStd-Black';
            padding-top: 60px;
            padding-bottom: 30px;
        }
        .secondary-desktop {
            display: none;
        }
        .secondary-wrapper.container-carousel {
            display: none;
        }
        .home_suite.container {
            padding: 0;
            max-width: 100%;
        }
        .container-wrapper.secondary-wrapper.home-suite-movile-show-more {
            display: block;
            .home-suite-movile-text {
                font-size: 15px;
                line-height: 20px;
                text-align: center;
            }
        }
    }
</style>