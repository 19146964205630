<script setup>
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue'

const { locale } = useI18n();
let location = ref(locale.value);

watch(locale, () => {
    location = locale.value;
});
</script>
<template>
    <section class="home_cases">
        <div class="image-wrapper">
            <div class="inner-wrapper">
                <div class="title">
                    {{ $t('home_success_histories.title') }}
                </div>
                <div class="subtitle">
                    {{ $t('home_success_histories.subtitle') }}
                </div>
                <div class="btn-wrapper">
                    <router-link :to="`/${location}/partners`" class="custom-btn">
                        {{ $t('home_success_histories.button') }}
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
.home_functionality {
    background-color: black;
}
.image-wrapper {
    height: 545px;
    background-image: url("/assets/es/home/cases/exit_case.webp");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    .inner-wrapper {
        max-width: 1080px;
        text-align: center;
    }
    .title {
        font-family: 'CircularStd-Black';
        letter-spacing: -3.25px;
        font-size: 65px;
    }
    .subtitle {
        font-size: 28px;
        padding-bottom: 25px;
    }
    .btn-wrapper {
        display: flex;
        justify-content: center;
        a {
            font-size: 18px;
            text-decoration: none;
            display: block;
        }
    }
}

@media (max-width: 1000px) {
    .home_cases {
        padding-top: 50px;
        padding-bottom: 60px;
        .title {
            font-size: 2rem;
            padding: 0 80px 30px;
            line-height: 2.3rem;
        }
        .subtitle {
            font-size: 1.1rem;
            padding: 0 80px 30px;
            line-height: 1.3rem;
        }
    }
}
@media (max-width: 600px) {
    .home_cases {
        padding-top: 50px;
        padding-bottom: 0px;
        .title {
            font-size: 2.3rem;
            padding: 0 60px 30px;
            line-height: 3.4rem;
            letter-spacing: -1.1px;
        }
        .subtitle {
            font-size: 1.1rem;
            padding: 0 80px 30px;
            line-height: 1.3rem;
        }
    }
}
</style>