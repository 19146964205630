<script setup>
    import App1 from '@/components/home/homeClientApp/App1.vue'
    import App2 from '@/components/home/homeClientApp/App2.vue'    
    import App3 from '@/components/home/homeClientApp/App3.vue'  
    import App4 from '@/components/home/homeClientApp/App4.vue'  
    import App5 from '@/components/home/homeClientApp/App5.vue'  
    import App6 from '@/components/home/homeClientApp/App6.vue'  
    import { useHomeSlideStore } from '@/stores/homeSlide.js'

    const props = defineProps({
        isHover: Boolean
    })
    
    const storeSlide = useHomeSlideStore()

    //variable if we need it
    let slider = setInterval(function(){
        if(!props.isHover) {
            if(storeSlide.getActive >= 6) {
                storeSlide.setActive(1);
            } else {
                storeSlide.setActive(storeSlide.getActive + 1)
            }
        }
    }, 5000);
</script>

<template>
    <div class="app-background">
        <div class="carousel">
            <div  class="inner">
                <div class="card">
                    <TransitionGroup  name="list" tag="div" >
                        <div class="card-wrapper" v-if="storeSlide.active == 1">
                            <App1 activeValue="1" />
                        </div>
                        <div v-else-if="storeSlide.active == 2">
                            <App2 activeValue="2" />
                        </div>
                        <div v-else-if="storeSlide.active == 3">
                            <App3 activeValue="3" />
                        </div>
                        <div v-else-if="storeSlide.active == 4">
                            <App4 activeValue="4" />
                        </div>
                        <div v-else-if="storeSlide.active == 5">
                            <App5 activeValue="5" />
                        </div>
                        <div v-else-if="storeSlide.active == 6">
                            <App6 activeValue="6" />
                        </div>
                    </TransitionGroup>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.app-background {
    background-image: url("/assets/es/home/app_client/MOCKUP_310.png");
    background-size: contain;
    height: 642px;
    background-repeat: no-repeat;
}
.card {
    border: none;
    background-color: transparent;
    position: relative;
    width: 310px;
    img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.card-wrapper {
    height: 610px;
    width: 320px;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 5 ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;
}

.list-enter-active {
    transition: all 2s ease-out;
}
.list-leave-active {
    transition: all .2s ease-out;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

</style>