import { library } from '@fortawesome/fontawesome-svg-core';

import { 
    faLinkedinIn, 
    faYoutube, 
    faTwitter, 
    faInstagram 
} from '@fortawesome/free-brands-svg-icons'

import { 
    faCirclePlus, 
    faCircle,
    faChevronDown,
    faPhone, 
    faLocationDot, 
    faEnvelope, 
    faPlus, 
    faXmark, 
    faDisplay, 
    faTabletScreenButton, 
    faMobileScreen,
    faAnglesRight,
    faBlog 
} from '@fortawesome/free-solid-svg-icons'

import { 
    faCircleCheck, 
} from '@fortawesome/free-regular-svg-icons'


library.add(
    faLinkedinIn, 
    faYoutube, 
    faTwitter, 
    faInstagram, 
    faCirclePlus, 
    faPhone, 
    faLocationDot,
    faEnvelope, 
    faCircle, 
    faPlus, 
    faXmark, 
    faCircleCheck, 
    faDisplay, 
    faTabletScreenButton, 
    faMobileScreen,
    faAnglesRight,
    faChevronDown,
    faBlog
)
