<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
let imageUrl = ref(new URL(`/src/assets/${locale.value}/app_client/MOCKUPWEB_310_whats_sense_ratlla.png`, import.meta.url).href)
watch(locale, () => {
  imageUrl.value = new URL(`/src/assets/${locale.value}/app_client/MOCKUPWEB_310_whats_sense_ratlla.png`, import.meta.url).href
});
</script>
<template>
    <img :src=imageUrl loading="lazy" />
</template>