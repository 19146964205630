<script setup>
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
let imageUrl = ref(new URL(`/src/assets/${locale.value}/app_client/MOCKUPWEB_310_encuesta.png`, import.meta.url).href)
watch(locale, () => {
  imageUrl.value = new URL(`/src/assets/${locale.value}/app_client/MOCKUPWEB_310_encuesta.png`, import.meta.url).href
});

</script>
<template>
    <img :src=imageUrl loading="lazy" />
</template>
<style lang="scss" scoped>
.image-wrapper {
    width: 100%;
    img {
        width: 100%;
    }
}
</style>